import React from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router';

import styles from './StoreRegisterUserSucces.module.scss';

const StoreRegisterUserSuccessPage = () => {
  const navigate = useNavigate();
  const {state} = useLocation();

  return (
    <Container fluid className={`px-lg-4 px-xs-2 my-auto ${styles.success}`}>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h1">
                ¡{state?.user_name}, tu cuenta fue creada!
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Te enviamos un correo a {state?.email} para crear tu contraseña
              </Card.Text>
              <Card.Text>
                Una vez creada, ya estas listo para utilizar nuestro sitio
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/')}>
                Ir a la tienda
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StoreRegisterUserSuccessPage;

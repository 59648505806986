import React, {useCallback} from 'react';
import {Form} from 'react-bootstrap';
import {useSearchParams} from 'react-router-dom';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';

import styles from './SearchParams.module.scss';

export interface SortChoice {
  value: string;
  label: string;
}

interface SearchParamsSortByProps {
  choices: SortChoice[];
}

interface SearchParamsSortByViewProps extends SearchParamsSortByProps {
  ordering: string;
  setOrdering: (newOrdering: string) => void;
}
const SortByViewDesktop = ({
  choices,
  ordering,
  setOrdering,
}: SearchParamsSortByViewProps) => (
  <div>
    <p className={styles.fullFilter}>
      Ordenar por
      <select
        onChange={(event) => setOrdering(event.target.value)}
        value={ordering}
      >
        {choices.map((choice) => (
          <option key={choice.value} value={choice.value}>
            {choice.label}
          </option>
        ))}
      </select>
    </p>
  </div>
);

const SortByViewMobile = ({
  choices,
  ordering,
  setOrdering,
}: SearchParamsSortByViewProps) => (
  <div className={styles.fullFilter}>
    <h5>Ordenar por</h5>
    {choices.map((choice) => (
      <Form.Check
        type="radio"
        label={choice.label}
        value={choice.value}
        checked={ordering === choice.value}
        onChange={(event) => setOrdering(event.target.value)}
        key={choice.value}
      />
    ))}
  </div>
);

const SearchParamsSortBy = ({choices}: SearchParamsSortByProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {isMobile} = useDeviceWindow();

  const ordering =
    searchParams.get('ordering') ||
    (searchParams.get('categories') ? 'name' : 'min_path');

  const setOrdering = useCallback(
    (newOrdering: string) => {
      setSearchParams((prev) => {
        prev.set('ordering', newOrdering);
        return prev;
      });
    },
    [setSearchParams],
  );

  if (isMobile) {
    return (
      <SortByViewMobile
        choices={choices}
        ordering={ordering}
        setOrdering={setOrdering}
      />
    );
  }

  return (
    <SortByViewDesktop
      choices={choices}
      ordering={ordering}
      setOrdering={setOrdering}
    />
  );
};

export default SearchParamsSortBy;
